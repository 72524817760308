import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Spinner } from 'libs.nucleus.spinner';
import { Tabs } from 'libs.nucleus.tabs';
import { PageHeader, PageWrapper } from 'libs.react.components';
import { useBooleanState } from 'libs.react.hooks';
import { ActivitiesList } from '../../components/activities_list/activities_list.component';
import { VisitDetails } from '../../components/visit_details/visit_details.component';
import { getApiInstance } from '../../helpers/api.helpers';
import { getRouteLink } from '../../helpers/links.helpers';
import { getParticipantDetails, getParticipantNumber } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useSiteContext } from '../../hooks/use_site_context';
export const VisitDetailsPage = () => {
    const [isModalOpen, setIsModalOpen, setIsModalClose] = useBooleanState(false);
    const match = useMatch('/site/study/:studyId/participants/:participantId/visit/:visitId');
    const participantId = match?.params.participantId;
    const visitId = match?.params.visitId;
    const { environment } = useCurrentEnvironment();
    const site = useSiteContext();
    const translate = useLocalizeMessage();
    const queryClient = useQueryClient();
    const getVisit = async () => {
        if (!environment) {
            return [];
        }
        const response = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_visit_schedule`, {
            params: {
                where: JSON.stringify({ c_visit: visitId }),
            },
        });
        return response?.data.data || {};
    };
    //  Getting participant details here because of the participant number on breadcrumbs
    //  this re-uses the cached data if exists so the call isn't always made
    const getParticipantData = async () => {
        if (!environment) {
            return [];
        }
        return await getParticipantDetails({
            environment,
            siteId: site?._id ?? '',
            participantId: participantId ?? '',
        });
    };
    const { data: participantDetails, isLoading: isParticipantLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: !queryClient.getQueryData(['participantDetails', participantId]),
    });
    const { data: visitData, isLoading } = useQuery({
        queryKey: ['getVisitDetails', participantId, visitId],
        queryFn: getVisit,
        enabled: true,
    });
    if (isLoading || isParticipantLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const visit = visitData?.[0];
    if (!visit) {
        throw new Error('Visit not found');
    }
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { breadcrumbPages: [
                    { name: translate('Participants'), path: getRouteLink(`participants`, environment) },
                    {
                        name: getParticipantNumber(participantDetails),
                        path: getRouteLink(`participants/${participantId}#visits`, environment),
                    },
                    { name: visit.c_name ?? '', path: '' },
                ], options: [{ name: translate('Start Visit'), onClick: () => setIsModalOpen(), variant: 'primary' }], title: visit.c_name ?? '' }), _jsx(Tabs, { tabLabels: [translate('Details'), translate('Responses')], tabContent: [
                    _jsx(VisitDetails, { visit: visit }, 'visit-details'),
                    _jsx("p", { children: "Visit Responses go here" }, 'visit-responses'),
                ], defaultIndex: 0, onTabChange: () => { } }), _jsx(ModalWindow, { title: visit.c_name, subtitle: '', isOpen: isModalOpen, hideCloseIcon: true, closeWindow: () => { }, footerSecondaryActionButtons: [
                    {
                        disabled: false,
                        label: translate('Exit Visit'),
                        content: undefined,
                        onClick: () => setIsModalClose(),
                    },
                ], width: 'full', removeBodyPadding: true, children: _jsx(ActivitiesList, { visit: visit, setIsActivityListModalClosed: () => { }, setVisitActivity: () => { }, setIsActivityModalOpen: () => { } }) })] }));
};
