import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Pill } from 'libs.nucleus.pill';
import { Spinner } from 'libs.nucleus.spinner';
import { H3, Text } from 'libs.nucleus.typography';
import { AuthContext } from 'libs.react.auth';
import { PageHeader, ListInformation, FormattedDateTime, } from 'libs.react.components';
import { useApiClient } from 'libs.react.contexts';
import { useBooleanState, useDateTime } from 'libs.react.hooks';
import { ServerTimeModal, StudyDeploymentStatus } from '../../components';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants';
import { StudyConfigurationContext } from '../../contexts';
import { useDeploymentUtils } from '../../hooks';
import { DeploymentUtils } from '../../utils/deployment';
import { getUTCOffset } from '../server_time_modal/server_time_modal.utils';
export const StudyDeploymentDetails = ({ envId, originPage }) => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { parseDateTimeShort } = useDateTime();
    const utcOffset = getUTCOffset();
    const { getRegionName } = useDeploymentUtils();
    const { entityId } = useContext(AuthContext);
    const { currentStudy, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const flagManager = useContext(FlagContext);
    const isCortexTokenEnabled = flagManager.getValue(STUDY_MANAGEMENT_FLAGS.getCortexToken);
    const [cortexToken, setCortexToken] = useState('');
    const [isChangeServerTimeModalOpen, openChangeServerTimeModal, closeChangeServerTimeModal] = useBooleanState(false);
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const getToken = async () => {
        const { data } = await momClient.post(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}/cortexToken`);
        return data.data;
    };
    const { mutate: getCortexToken, isPending } = useMutation({
        mutationKey: ['entities', entityId, 'studies', studyId, 'environment', envId],
        mutationFn: getToken,
        onSuccess: (token) => {
            if (token) {
                setCortexToken(token);
                logEvent('get_cortex_token', { entity_id: entityId, study_id: studyId, env_id: envId });
            }
        },
    });
    const retrieveStudyEnv = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}?withWorkflows=true`);
        return response.data;
    };
    const { data: studyEnv, isLoading, isError, } = useQuery({
        enabled: !!envId,
        queryFn: retrieveStudyEnv,
        queryKey: ['entities', entityId, 'studies', studyId, 'environment', envId],
    });
    const retrieveServerTime = async () => {
        if (!studyEnv || !DeploymentUtils.isTimeTravelEnvironment(studyEnv)) {
            return;
        }
        const cortexClusterUrl = studyEnv.cortexURL;
        const { data: serverTime } = await axios.get(`${cortexClusterUrl}/settime/`);
        return serverTime.date;
    };
    const { data: currentServerDateTime } = useQuery({
        enabled: !!studyEnv,
        queryFn: retrieveServerTime,
        queryKey: ['entities', entityId, 'studies', studyId, 'environment', envId, 'serverTime'],
    });
    const isTimeTravel = studyEnv && DeploymentUtils.isTimeTravelEnvironment(studyEnv);
    const deploymentStatus = studyEnv && DeploymentUtils.getStatus(studyEnv);
    const listItems = useMemo(() => {
        if (!studyEnv) {
            return [];
        }
        const packages = studyEnv.metadata.packages || [];
        const buildPackage = packages.find((pkg) => pkg.name === currentStudy.name);
        const workflow = DeploymentUtils.getEnvironmentWorkflow(studyEnv);
        const shouldShowServerTime = isTimeTravel && currentServerDateTime && deploymentStatus === 'completed';
        const items = [
            { title: translate('Description'), description: studyEnv.metadata.description },
            { title: translate('Status'), description: _jsx(StudyDeploymentStatus, { studyToDeploy: studyEnv }) },
            {
                title: translate('Error'),
                description: workflow?.result?.error?.message,
                hideIfEmpty: true,
            },
            {
                title: translate('Type'),
                description: isTimeTravel ? translate('Time travel') : translate('Standard'),
            },
            {
                title: translate('Server time'),
                description: (_jsxs(Text, { size: 'sm', children: [_jsx(FormattedDateTime, { date: currentServerDateTime }), " ", translate('(UTC{offset})', { offset: utcOffset })] })),
                actionContent: (_jsx(Button, { variant: 'transparent', label: translate('Change server time'), onClick: openChangeServerTimeModal })),
                hidden: !shouldShowServerTime,
            },
            { title: translate('Build version'), description: buildPackage?.version || '' },
            { title: translate('Owner'), description: studyEnv.owner.email },
            { title: translate('Date created'), description: parseDateTimeShort(studyEnv.createdAt) },
            { title: translate('Last updated'), description: parseDateTimeShort(studyEnv.updatedAt) },
            { title: translate('Internal name'), description: studyEnv.code },
        ];
        if (isCortexTokenEnabled) {
            items.push({
                title: translate('Cortex Token'),
                description: cortexToken ? (_jsx(Text, { size: 'sm', className: 'text-text-primary break-all', children: cortexToken })) : isPending ? (_jsx(Spinner, { size: 'sm' })) : (_jsx(Button, { variant: 'secondary', label: translate('Get cortex token'), onClick: () => getCortexToken() })),
            });
        }
        return items;
    }, [studyEnv, cortexToken, isPending, currentServerDateTime, deploymentStatus]);
    const launchEnv = () => {
        if (studyEnv) {
            const url = DeploymentUtils.buildEnvironmentUrl(studyEnv);
            logEvent('launch_env_sandbox', { study_id: studyId, env_name: studyEnv.name });
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };
    const breadcrumbsPages = [originPage, { name: studyEnv?.metadata.name || '', path: '' }];
    const headerOptions = deploymentStatus === 'completed' ? [{ name: translate('Launch'), onClick: launchEnv, variant: 'primary' }] : [];
    if (isError) {
        navigate(`/studies/${studyId}/tests`);
    }
    const TitleElement = (_jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(H3, { className: 'text-4xl font-light text-text-primary', children: studyEnv?.name || '' }), studyEnv && (_jsx("div", { className: 'pt-1', children: _jsx(Pill, { label: getRegionName(studyEnv.region), backgroundColor: 'cyan' }) }))] }));
    return (_jsxs("section", { className: 'relative p-6 h-full flex flex-col', children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: TitleElement, options: headerOptions }), _jsxs("div", { className: 'flex-1 overflow-auto relative', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx(ListInformation, { items: listItems })] }), isTimeTravel && isChangeServerTimeModalOpen && (_jsx(ServerTimeModal, { serverDateTime: currentServerDateTime, studyEnv: studyEnv, onClose: closeChangeServerTimeModal }))] }));
};
