import JSZip from 'jszip';
const getMimeType = (extension) => {
    switch (extension) {
        case 'png':
            return 'image/png';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'gif':
            return 'image/gif';
        case 'html':
            return 'text/html';
        case 'css':
            return 'text/css';
        case 'js':
            return 'text/javascript';
        default:
            return 'text/plain';
    }
};
const IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif'];
class BundleHelper {
    files;
    bundle;
    constructor() {
        this.files = [];
        this.bundle = null;
    }
    async loadBundle(bundle) {
        this.bundle = bundle;
        await this.unzipBundle();
    }
    async unzipBundle() {
        if (!this.bundle) {
            throw new Error('No bundle to unzip');
        }
        try {
            const zip = new JSZip();
            const zipFile = await zip.loadAsync(this.bundle);
            for (const relativePath in zipFile.files) {
                const extension = relativePath?.split('.')?.pop()?.toLowerCase();
                let fileData;
                // Check file extension and choose appropriate method
                if (IMAGE_EXTENSIONS.includes(extension || '')) {
                    // Treat image files as binary data
                    fileData = await zipFile.files[relativePath].async('base64');
                }
                else {
                    // Treat all other files as strings
                    fileData = await zipFile.files[relativePath].async('string');
                }
                this.files.push({
                    name: relativePath,
                    data: fileData,
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    getFiles() {
        return this.files;
    }
    getBlobURL(source, type) {
        const blob = new Blob([source], { type });
        return URL.createObjectURL(blob);
    }
    getImageURL(name, image) {
        const extension = name.split('.').pop();
        if (extension) {
            const type = getMimeType(extension);
            return `data:${type};base64,${image}`;
        }
        return '';
    }
    getScreenURL(identifier) {
        const html = this.files.find((file) => file.name === `index.html`);
        if (!html) {
            throw new Error('No HTML file found');
        }
        let parseHTML = html.data;
        // Get image files
        const images = this.files.filter((file) => {
            const extension = file.name.split('.').pop();
            if (!extension) {
                return false;
            }
            return IMAGE_EXTENSIONS.includes(extension);
        });
        // Get JS files, filter out hook.js so as to use different implementation later
        const jsFiles = this.files.filter((file) => file.name.endsWith('.js') || file.name === 'hook.js');
        // Loop through JS files and replace image urls with base64 data
        // Then replace js src with blob url
        jsFiles.forEach((jsFile) => {
            images.forEach((image) => {
                const imageURL = this.getImageURL(image.name, image.data);
                jsFile.data = jsFile.data.replace(image.name, imageURL);
            });
            parseHTML = parseHTML.replace(jsFile.name, this.getBlobURL(jsFile.data, 'text/javascript'));
        });
        // Inject init step
        parseHTML = parseHTML.replace('</head>', `
      <script type='text/javascript'>
        const types = {
          SUBMIT: 'submit',
          TRACK: 'track',
          CANCEL: 'cancel'
        }

        function post(type, message) {
          window.parent.postMessage({ type, message }, '*');
        }

        function submit({ responses, events }) {
          post(types.SUBMIT, { responses, events })
        }

        /**
         * Sends tracking data to the client app.
         *
         * @param { object } event - tracking event as a json object
         */
        function track(event) {
          post(types.TRACK, event)
        }

        /**
         * Sends a Cancel request to the client app.
         * The app should close the task.
         */
        function cancel() {
          post(types.CANCEL, '')
        }
      
      </script>
      <script type='text/javascript'">window.INSTRUMENT_STEP='${identifier}'; </script></head>`);
        // Return blob url of the HTML that will be iframe src
        return this.getBlobURL(parseHTML, 'text/html');
    }
}
export default BundleHelper;
