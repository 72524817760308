import { EnvironmentCluster } from '../../types';
const getStatus = (environment) => {
    const workflow = getEnvironmentWorkflow(environment);
    if (workflow) {
        return workflow.status || 'active';
    }
    return environment.metadata.packages.length > 0 ? 'completed' : 'failed';
};
const buildEnvironmentUrl = (studyTest, app = 'apps-dashboard') => {
    const cortexweb = studyTest.cortexURL.replace('api', app);
    return `${cortexweb}/?orgCode=${studyTest.code}`;
};
const getEnvironmentWorkflow = (environment) => {
    return Array.isArray(environment.workflow) ? environment.workflow[0] : environment.workflow;
};
const isTimeTravelEnvironment = (environment) => {
    return environment.cluster === EnvironmentCluster.EPHEMERAL;
};
export const DeploymentUtils = {
    buildEnvironmentUrl,
    getEnvironmentWorkflow,
    getStatus,
    isTimeTravelEnvironment,
};
