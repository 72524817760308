import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { Link as RouterLink, useMatch } from 'react-router-dom';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage, LocaleContext } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Spinner } from 'libs.nucleus.spinner';
import { SectionCard } from 'libs.react.components';
import { useBooleanState } from 'libs.react.hooks';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { getRouteLink } from '../../helpers/links.helpers';
import { formatVisitWindow } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { ActivitiesList } from '../activities_list/activities_list.component';
import { ActivityView } from '../activity/activity_modal/activity_view';
import { Card } from '../card';
export const ParticipantVisits = ({ participantNumber }) => {
    const [isActivityListModalOpen, setIsActivityListModalOpen, setIsActivityListModalClosed] = useBooleanState(false);
    const [isActivityModalOpen, setIsActivityModalOpen, setIsActivityModalClose] = useBooleanState(false);
    const [visitActivity, setVisitActivity] = useState({});
    const [currentVisit, setCurrentVisit] = useState({});
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const getParticipantVisitsData = async () => {
        if (!environment) {
            return [];
        }
        try {
            const response = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_visit_schedule`);
            return response?.data.data || [];
        }
        catch (error) {
            return [];
        }
    };
    const { data: participantVisits, isLoading } = useQuery({
        queryKey: ['participantVisits', participantId],
        queryFn: getParticipantVisitsData,
        enabled: true,
    });
    if (!participantVisits || isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (participantVisits.length === 0) {
        return (_jsx(SectionCard, { children: _jsx("section", { className: 'flex flex-col items-center justify-center p-10 m-10', children: _jsx("p", { className: 'mb-8 text-sm text-secondary', children: translate('No visits have been created') }) }) }));
    }
    return (_jsxs("div", { className: 'flex flex-col gap-8', children: [_jsx(ModalWindow, { title: currentVisit.c_name, subtitle: participantNumber, isOpen: isActivityListModalOpen, hideCloseIcon: true, closeWindow: () => { }, footerSecondaryActionButtons: [
                    {
                        disabled: false,
                        label: translate('Exit Visit'),
                        content: undefined,
                        onClick: () => {
                            setCurrentVisit({});
                            setIsActivityListModalClosed();
                        },
                    },
                ], width: 'full', removeBodyPadding: true, children: _jsx(ActivitiesList, { visit: currentVisit, setIsActivityListModalClosed: setIsActivityListModalClosed, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen }) }), _jsx(ModalWindow, { title: visitActivity.activity?.c_group_task ? visitActivity.activity.c_group_task.c_assignment.c_name : '', subtitle: '', isOpen: isActivityModalOpen, hideCloseIcon: true, closeWindow: () => { }, width: 'full', children: _jsx(ActivityView, { visitActivity: visitActivity, setIsActivityModalClose: setIsActivityModalClose }) }), participantVisits.map((visit) => {
                return (_jsx(Card, { children: _jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("div", { className: 'flex gap-4', children: _jsx(Link, { LinkComponent: RouterLink, to: getRouteLink(`participants/${participantId}/visit/${visit.c_visit}`, environment), "data-testid": 'visit-name', children: visit.c_name }) }), _jsxs("div", { className: 'flex gap-2 items-center', children: [_jsx("span", { className: 'text-gray-500', "data-testid": `visit-period-${visit.c_visit}`, children: formatVisitWindow({
                                            windowStartDate: visit.c_window_start_date,
                                            windowEndDate: visit.c_window_end_date,
                                            currentLocale,
                                        }) }), _jsx("span", { children: "\u2022" }), _jsx("span", { className: 'text-gray-500', "data-testid": `visit-assessments-${visit.c_visit}`, children: translate('{assignments} assessments', { assignments: visit.assignments }) }), _jsx("span", { className: 'ml-auto', children: _jsx(Button, { label: translate('Start visit'), onClick: () => {
                                                setCurrentVisit(visit);
                                                setIsActivityListModalOpen();
                                            } }) })] })] }, visit.c_visit) }, visit.c_visit));
            })] }));
};
