import { AssetRole, AssetTypes, LocalizationStatus, } from 'libs.localization.common';
import { StudyAssetType } from '../types';
export const mapStudyAssetToRole = (asset) => {
    switch (asset) {
        case AssetTypes.ACTIVITY_NAMES:
            return AssetRole.BOTH;
        case AssetTypes.SIGN_IN_SUPPORT_INFORMATION:
        case AssetTypes.SIGN_IN_STUDY_NAME_PII:
        case AssetTypes.SIGN_IN_STUDY_NAME:
            return AssetRole.PARTICIPANT;
        case AssetTypes.PROTOCOL_SCHEDULE:
        case AssetTypes.PARTICIPANT_STATUSES:
        case AssetTypes.SITE_VISIT_SCHEDULE:
            return AssetRole.SITE;
        default:
            return AssetRole.BOTH;
    }
};
export const getTypeByAvailableTo = (availableTo) => {
    switch (availableTo) {
        case 'patient_only':
            return StudyAssetType.PARTICIPANT_RESOURCES;
        case 'site_only':
            return StudyAssetType.SITE_RESOURCES;
        case 'site_patient':
        default:
            return StudyAssetType.SITE_PARTICIPANT_RESOURCES;
    }
};
export const getRolebyAvailableTo = (availableTo) => {
    switch (availableTo) {
        case 'patient_only':
            return AssetRole.PARTICIPANT;
        case 'site_only':
            return AssetRole.SITE;
        case 'site_patient':
        default:
            return AssetRole.BOTH;
    }
};
export const getVersionStatus = ({ localizationEntry, siteLocales, participantLocales, role, l10nMap = [], translationWorkbenchId, assetType, }) => {
    const latestVersionStatus = {};
    const currentVersionStatus = {};
    const isValidLocaleByRole = (locale) => {
        if (role === AssetRole.PARTICIPANT) {
            return participantLocales.includes(locale);
        }
        if (role === AssetRole.SITE) {
            return siteLocales.includes(locale);
        }
        return true; // for both roles
    };
    const _locales = [...siteLocales, ...participantLocales].filter((l) => isValidLocaleByRole(l));
    for (const locale of _locales) {
        if (localizationEntry) {
            const { supported_locales } = localizationEntry;
            latestVersionStatus[locale] = {
                status: supported_locales?.[locale]?.status || LocalizationStatus.NOT_STARTED,
                version: Number(supported_locales?.[locale]?.version || 0),
            };
            const existingL10nObject = l10nMap.find((_entry) => _entry.locale === locale && _entry.translationWorkbenchId === translationWorkbenchId);
            if (existingL10nObject && assetType === AssetTypes.ACTIVITY) {
                currentVersionStatus[locale] = {
                    status: existingL10nObject.status || LocalizationStatus.NOT_STARTED,
                    version: Number(existingL10nObject.translationWorkbenchVersion || 0),
                };
            }
            else {
                currentVersionStatus[locale] = latestVersionStatus[locale];
            }
        }
        else {
            latestVersionStatus[locale] = {
                status: LocalizationStatus.NOT_STARTED,
                version: 0,
            };
            currentVersionStatus[locale] = {
                status: LocalizationStatus.NOT_STARTED,
                version: 0,
            };
        }
    }
    return { latestVersionStatus, currentVersionStatus };
};
