import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { useLocalization } from 'libs.localization.react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Workflow } from 'libs.nucleus.workflow';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { LocaleStatusChangeModal } from './locale_status_change_modal.component';
import { useLocalizedStatusChange } from '../../hooks';
import { useLocalizedBundle } from '../../queries/use_localized_bundle';
export const LocaleStatusProgress = ({ asset, locale, assetId, isDisabled = false, isCustomActivity = false, trackingContext = {}, onStatusChange, }) => {
    const t = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const { logEvent } = useContext(FirebaseAppContext);
    const { localizationEntry, localization } = useLocalization(asset);
    const status = localizationEntry?.supported_locales?.[locale]?.status;
    const version = localizationEntry?.supported_locales?.[locale]?.version;
    const enableLocalizedBundle = asset && ('activity' in asset || asset.type === AssetTypes.ACTIVITY);
    const { isPending, mutate: pushTranslationBundle } = useLocalizedBundle(isCustomActivity);
    const { mutate: setLocalizationStatus } = useLocalizedStatusChange();
    const statusProps = useMemo(() => [
        {
            iconName: 'statusNotStarted',
            color: 'redPale',
            label: t('Not Started'),
            value: LocalizationStatus.NOT_STARTED,
        },
        {
            iconName: 'statusInProgress',
            color: 'orange',
            label: t('In Progress'),
            value: LocalizationStatus.IN_PROGRESS,
        },
        {
            iconName: 'statusInReview',
            color: 'yellowPale',
            label: t('In Review'),
            value: LocalizationStatus.IN_REVIEW,
        },
        {
            iconName: 'statusReady',
            color: 'green',
            label: t('Ready for use'),
            value: LocalizationStatus.READY,
        },
    ], []);
    const onHandleStatus = useCallback(async (selectedStatus) => {
        if (!localization) {
            addNotification({
                title: t('Internal error. Localization instance is missing.'),
                type: 'error',
            });
            return;
        }
        if (!version) {
            addNotification({
                title: t('Internal error. Version is missing.'),
                type: 'error',
            });
            return;
        }
        if (assetId) {
            logEvent('translations_localization_status_changed', {
                ...trackingContext,
                previousStatus: status,
                status: selectedStatus,
                locale,
                id: assetId,
            });
        }
        // Status: Ready for use
        if (selectedStatus === LocalizationStatus.READY && assetId) {
            // Activities have a special route (instrument bundler)
            if (enableLocalizedBundle) {
                pushTranslationBundle({ resourceId: assetId, locales: { [locale]: Number(version) } }, {
                    onSuccess: () => {
                        closeModal();
                        if (onStatusChange) {
                            onStatusChange({ status: selectedStatus, id: localization.getId(), version, locale });
                        }
                    },
                });
                return;
            }
            // All other assets go here.
            setLocalizationStatus({ asset, locale, status: selectedStatus }, {
                onSuccess: () => {
                    closeModal();
                    if (onStatusChange) {
                        onStatusChange({ status: selectedStatus, id: localization.getId(), version, locale });
                    }
                },
            });
            return;
        }
        // All other statuses
        await localization?.setStatus(locale, selectedStatus);
        if (onStatusChange) {
            onStatusChange({ status: selectedStatus, id: localization.getId(), version, locale });
        }
        closeModal();
    }, [status, version, localization]);
    const currentStatusIndex = statusProps.findIndex((statusProp) => statusProp.value === status);
    return (_jsxs("div", { className: 'flex gap-4 items-center relative', "data-testid": 'translation-status-workflow', children: [_jsx("div", { className: 'flex', children: _jsx(Workflow, { statuses: statusProps, activeStatusIndex: currentStatusIndex < 0 ? 0 : currentStatusIndex }) }), _jsx(Button, { variant: 'secondary', label: 'Change status', onClick: openModal, disabled: isDisabled }), isModalOpen && status && (_jsx(LocaleStatusChangeModal, { isChanging: isPending, onModalClose: closeModal, onHandleStatus: onHandleStatus, status: status }))] }));
};
