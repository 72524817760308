import { ECOA_BUILDER_FLAGS } from 'apps.ecoa_builder';
import { SITE_FLAGS } from 'apps.site_app';
import { STUDY_MANAGEMENT_FLAGS } from 'apps.study_management';
import { USER_MANAGEMENT_FLAGS } from 'apps.user_management';
import { FlagManager } from 'libs.flags.core';
import { ConsoleFlagConnector, CookieFlagConnector, UrlFlagConnector } from 'libs.flags.web';
import { TRANSLATION_MANAGEMENT_FLAGS } from 'libs.translation_workbench';
export const SELF_SERVICE_FLAGS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, STUDY_MANAGEMENT_FLAGS), USER_MANAGEMENT_FLAGS), ECOA_BUILDER_FLAGS), SITE_FLAGS), TRANSLATION_MANAGEMENT_FLAGS);
export const flagManager = new FlagManager(process.env.ENABLE_DEVELOPER_FLAGS === 'true' || process.env.ENABLE_DEVELOPER_FLAGS === 'route-only'
    ? SELF_SERVICE_FLAGS
    : {});
flagManager.registerConnector((manager) => new CookieFlagConnector(manager));
flagManager.registerConnector((manager) => new UrlFlagConnector(manager));
flagManager.registerConnector((manager) => new ConsoleFlagConnector(manager));
