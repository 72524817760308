import { ActivityResponse } from './activity_response';
export class ActivityManager {
    static key = 'activityManager';
    taskResponse = {};
    /**
     * Set the activity/task response data
     *
     * @param activityId
     * @param studyId
     * @param publicUserId
     * @param currentGroupId
     * @param visitId
     * @param siteId
     * @param currentLocale
     * @returns
     */
    initActivityResponseData({ activityId, studyId, publicUserId, currentGroupId, visitId, siteId, currentLocale, }) {
        const taskResponseData = {
            c_task: activityId,
            c_study: studyId,
            c_start: new Date(),
            c_group: currentGroupId,
            c_locale: currentLocale,
            c_public_user: publicUserId,
            c_site: siteId,
            ...(visitId ? { c_visit: visitId } : {}),
        };
        const activityResponse = new ActivityResponse(taskResponseData);
        return activityResponse;
    }
}
export const activityManager = new ActivityManager();
