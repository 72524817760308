import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useLocalization } from 'libs.localization.react';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { TranslationWorkbenchContext } from '../../contexts';
import { useAssetContext } from '../../hooks/use_asset_context.hook';
import { VersionedTableImport } from '../import_table/versioned_table_import.component';
import { EmptySection } from '../section_empty';
export const SectionImport = ({ asset }) => {
    const translate = useLocalizeMessage();
    const { localizationEntry, isLoading, localization } = useLocalization(asset);
    const { getAssetByTranslationWorkbenchId } = useContext(TranslationWorkbenchContext);
    const { getReviewUrl } = useAssetContext();
    const assetLocaleData = getAssetByTranslationWorkbenchId?.(localization?.getId() ?? '');
    const availableLocales = useMemo(() => {
        if (!localization?.getId()) {
            return [];
        }
        if (!assetLocaleData) {
            return [];
        }
        return assetLocaleData?.currentVersionStatus
            ? Object.keys(assetLocaleData.currentVersionStatus).filter((locale) => locale !== Locale.en_US)
            : [];
    }, [localization, getAssetByTranslationWorkbenchId, assetLocaleData]);
    const data = availableLocales.map((locale) => {
        const { last_updated, status } = localizationEntry?.supported_locales?.[locale] || {};
        return {
            locale: locale,
            status: status,
            last_updated: last_updated ? last_updated.toDate().toISOString() : '',
            version: '', // will be deprecated
            reviewPath: `${getReviewUrl()}/${locale}?assetType=${asset?.type}`,
            latestVersionStatus: assetLocaleData?.latestVersionStatus?.[locale],
            currentVersionStatus: assetLocaleData?.currentVersionStatus?.[locale],
        };
    });
    if (data.length > 0) {
        // return <TableImport data={data} />;
        // Note: Duplicated in case this need to put behind a developer flag
        return _jsx(VersionedTableImport, { data: data, hasOptions: true, asset: asset });
    }
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    return _jsx(EmptySection, { text: translate('Imported strings will appear here') });
};
