import { useMemo, useEffect, useState } from 'react';
import BundleHelper from './bundle_helper/bundle_helper';
export const useBundleHelper = (bundle) => {
    const bundlePreview = useMemo(() => new BundleHelper(), []);
    const [isBundleLoaded, setIsBundleLoaded] = useState(false);
    useEffect(() => {
        (async () => {
            if (bundle) {
                await bundlePreview.loadBundle(bundle);
                setIsBundleLoaded(true);
            }
        })();
    }, [bundle]);
    return { bundlePreview: bundle && isBundleLoaded ? bundlePreview : null };
};
export default useBundleHelper;
