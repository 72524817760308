import { useMemo } from 'react';
import { AssetRole, AssetTypes, LocalizationStatus, } from 'libs.localization.common';
import { useLocalizationCollection } from 'libs.localization.react';
import { getVersionStatus } from '../utils/data_utils';
export const useLibraryData = (activities = [], participantLocales = [], siteLocales = [], l10nMap = []) => {
    const filteredActivities = activities.filter((activity) => !(activity.isBuiltIn || activity.isVisitConfirmation));
    const assets = filteredActivities.map((activity) => ({
        type: AssetTypes.ACTIVITY,
        id: activity.details.code,
        version: '1.0',
    }));
    const locales = Array.from(new Set([...participantLocales, ...siteLocales]));
    const { localizationsMap, isLoading } = useLocalizationCollection(assets);
    const _data = useMemo(() => {
        const data = {};
        if (filteredActivities.length === 0) {
            return data;
        }
        for (const activity of filteredActivities) {
            const localeData = {};
            for (const locale of locales) {
                localeData[locale] = locale === 'en-US' ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED; // @TODO: Query for the actual status
            }
            const _asset = assets.find((a) => a.id === activity.details.code);
            const { localizationEntry, localization, } = localizationsMap?.get(_asset) || {};
            const translationWorkbenchId = localization?.getId();
            const role = activity.responderRole === 'Site' ? AssetRole.SITE : AssetRole.PARTICIPANT;
            const versionStatus = getVersionStatus({
                localizationEntry,
                participantLocales,
                siteLocales,
                role,
                l10nMap,
                translationWorkbenchId,
                assetType: AssetTypes.ACTIVITY,
            });
            data[activity.name] = {
                translationWorkbenchId,
                statuses: localeData,
                type: `${activity.responderRole} activities`,
                path: activity.details.libraryId || '', // Replacing activity.details.code with activity.details.libraryId
                assetType: AssetTypes.ACTIVITY,
                id: activity.details.libraryId,
                buildVersion: activity.details.buildVersion,
                asset: _asset,
                role,
                ...versionStatus,
            };
        }
        return data;
    }, [filteredActivities, localizationsMap]);
    return { data: _data, isLoading };
};
