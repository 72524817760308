import { useQuery } from '@tanstack/react-query';
import { ResourceType } from 'libs.library_manager';
import { useApiClient } from 'libs.react.contexts';
export function useL10nMapFetch(resourceId) {
    const { get } = useApiClient("library" /* ApiClientService.LIBRARY */);
    return useQuery({
        queryKey: ['study', 'l10nmap', 'fetch'],
        queryFn: async () => {
            const response = await get(`/v1/${ResourceType.L10N_MAP}/${resourceId}`);
            return response.data?.data?.data?.mapping || {};
        },
        retry: false,
        enabled: !!resourceId,
    });
}
