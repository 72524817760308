export class ActivityResponse {
    stepResponses = [];
    c_task;
    c_study;
    c_account;
    c_public_user;
    c_group;
    c_locale;
    c_site;
    c_start;
    c_event;
    c_visit;
    constructor({ c_task, c_study, c_account, c_public_user, c_group, c_locale, c_site, c_start, c_event, c_visit, }) {
        this.c_task = c_task;
        this.c_study = c_study;
        this.c_account = c_account;
        this.c_public_user = c_public_user;
        this.c_group = c_group;
        this.c_locale = c_locale;
        this.c_site = c_site;
        this.c_start = c_start;
        this.c_event = c_event;
        this.c_visit = c_visit;
    }
    async upload() {
        // TODO: Implement upload in next MR
        const { c_task, c_study, 
        // c_account,
        c_public_user, c_group, 
        //   c_locale,
        //   c_success,
        c_site, c_event, c_visit, } = this;
        console.log(`Uploading activity response for task ${c_task} in study ${c_study} for user ${c_public_user} in group ${c_group} at site ${c_site} with event ${c_event} and visit ${c_visit}`);
    }
}
