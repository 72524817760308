import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { LocaleContext } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { ActivityRunner } from './activity_runner';
import { useCurrentEnvironment } from '../../../hooks/use_current_environment';
import { useSiteContext } from '../../../hooks/use_site_context';
import { activityManager } from '../helper_models/activity_manager';
import { useBundleHelper } from '../use_bundle_helper';
const messageTypes = {
    SUBMIT: 'submit',
    TRACK: 'track',
    CANCEL: 'cancel',
};
export const ActivityView = ({ visitActivity, setIsActivityModalClose }) => {
    const activity = visitActivity.activity.c_group_task.c_assignment;
    const currentVisit = visitActivity.visit;
    const { environment } = useCurrentEnvironment();
    const [basePreviewURL, setBasePreviewURL] = useState();
    const site = useSiteContext();
    const currentLocale = useContext(LocaleContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const fetchAWSBundle = async () => {
        const response = await fetch(activity.c_html_bundle.url);
        const blobResponse = await response.blob();
        return blobResponse;
    };
    const { data: activityBlob, isLoading } = useQuery({
        queryKey: ['activityBundle', activity._id],
        queryFn: fetchAWSBundle,
        enabled: true,
    });
    const { bundlePreview: activityBundleView } = useBundleHelper(activityBlob);
    const handleIframeMessage = ({ data }) => {
        const { type, message } = data;
        if (type === messageTypes.CANCEL) {
            setIsActivityModalClose();
            return;
        }
        // TODO: implement submit in next MR
        if (type === messageTypes.SUBMIT) {
            const { responses } = message;
            console.log('responses', responses);
        }
    };
    // TODO: still working on rendering this into iframe
    useEffect(() => {
        if (activityBundleView) {
            const url = activityBundleView.getScreenURL(''); // gets the first screen
            setBasePreviewURL(url);
        }
        window.addEventListener('message', handleIframeMessage);
        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    }, [activityBundleView]);
    if (activity) {
        activityManager.initActivityResponseData({
            activityId: activity._id,
            studyId: environment?.studyId.toString() || '',
            publicUserId: match?.params.participantId || '',
            currentGroupId: currentVisit.c_group,
            visitId: currentVisit.c_visit,
            siteId: site._id || '',
            currentLocale,
        });
    }
    else {
        // show alert notification?
        return _jsx("div", {});
    }
    if (isLoading || !basePreviewURL) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    return _jsx(ActivityRunner, { basePreviewURL: basePreviewURL });
};
