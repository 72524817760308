import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Locale, getLocaleMetaInfo } from 'libs.i18n-core';
import { AssetTypes } from 'libs.localization.common';
import { Dropdown } from 'libs.nucleus.dropdown';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { Table } from 'libs.nucleus.table';
import { Text } from 'libs.nucleus.typography';
import { FormattedDateTime } from 'libs.react.components';
import { useBooleanState } from 'libs.react.hooks';
import { useAssetContext } from '../../hooks';
import { sortLocaleSensitive } from '../../utils';
import { AssetVersion } from '../asset_version';
import { AssetVersionSelectionModal } from '../asset_version_selection';
import { DashboardImportAction } from '../dashboard_import_action';
export const VersionedTableImport = ({ checklink = false, data, ids, enableImport = false, hasAction = false, hasOptions = false, trackingContext = {}, asset, }) => {
    const translate = useLocalizeMessage();
    const { assetType, isStudy } = useAssetContext();
    const [isVersionModalOpen, openVersionModal, closeVersionModal] = useBooleanState(false);
    const [selectedLocale, setSelectedLocale] = useState(null);
    const sortedData = useMemo(() => sortLocaleSensitive(data, 'locale'), [data]);
    const columns = useMemo(() => {
        const colData = [
            {
                header: translate('Locale'),
                accessorKey: 'locale',
                cell: ({ row }) => {
                    if (checklink && !row.original.last_updated) {
                        return (_jsx(Text, { size: 'sm', className: 'text-secondary', children: row.original.locale }));
                    }
                    return (_jsx(Link, { LinkComponent: RouterLink, to: row.original.reviewPath, children: _jsx("span", { className: 'whitespace-nowrap', children: row.original.locale }) }));
                },
            },
            {
                header: translate('Language/script'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { language } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return language;
                },
            },
            {
                header: translate('Country/Region'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return region;
                },
            },
            {
                accessorKey: 'currentVersionStatus',
                header: () => {
                    return (_jsx("div", { className: 'text-center', children: assetType === AssetTypes.ACTIVITY ? translate('Study version') : translate('Locale version') }));
                },
                cell: ({ row }) => {
                    return row.original.currentVersionStatus ? (_jsx(AssetVersion, { currentVersion: row.original.currentVersionStatus })) : ('—');
                },
            },
        ];
        if (isStudy && assetType === AssetTypes.ACTIVITY) {
            colData.push({
                accessorKey: 'latestVersionStatus',
                header: () => {
                    return (_jsx("div", { className: 'text-center', children: assetType === AssetTypes.ACTIVITY ? translate('Library version') : translate('Locale version') }));
                },
                cell: ({ row }) => {
                    return row.original.latestVersionStatus ? (_jsx(AssetVersion, { currentVersion: row.original.latestVersionStatus })) : ('—');
                },
            });
        }
        colData.push({
            header: translate('Last import'),
            cell: ({ row }) => row.original.last_updated ? (_jsx("div", { className: 'min-w-32', children: _jsx(FormattedDateTime, { date: row.original.last_updated }) })) : ('—'),
        });
        if (hasAction) {
            colData.push({
                header: ' ',
                accessorKey: 'action',
                cell: ({ row }) => row.original.action,
            });
        }
        if (hasOptions && assetType === AssetTypes.ACTIVITY) {
            colData.push({
                id: 'options',
                size: 20,
                cell: ({ row }) => {
                    const dropdownItems = [
                        {
                            id: 'change_version',
                            content: translate('View or change versions'),
                            onClick: () => handleOptionsClick(row.original),
                        },
                    ];
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownItems, variant: 'kebab' }) }));
                },
            });
        }
        return colData;
    }, [data]);
    const handleOptionsClick = useCallback((data) => {
        setSelectedLocale(data);
        openVersionModal();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex flex-col gap-6 items-end', "data-testid": 'table-translations-import', children: [enableImport && (_jsx("div", { className: 'flex items-center gap-4', children: _jsx(DashboardImportAction, { isDisabled: false, variant: 'primary', label: translate('Import translation'), requiredIds: ids, trackingContext: trackingContext }) })), _jsx("div", { className: 'w-full', children: _jsx(Table, { data: sortedData, columns: columns }) })] }), isVersionModalOpen && selectedLocale && (_jsx(AssetVersionSelectionModal, { data: selectedLocale, asset: asset, onModalClose: closeVersionModal, displayAssetName: asset?.human_id || asset?.id || '' }))] }));
};
